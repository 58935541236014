﻿<!--<div class="{{::ctrl.cssClasses.harmonicaClassTile}}"-->
<!--      data-ng-hide="!ctrl.isVisibleTile"-->
<!--      ng-class="{'harmonica-tile-active' : ctrl.isVisibleTileSubmenu}">-->
<div class="harmonica-tile-inner" data-ng-if="ctrl.isVisibleTile">
    <span class="icon-dot-3-before harmonica-tile-dots" data-window-click="ctrl.clickOut(event)"></span>
    <div
        class="js-harmonica-tile-list harmonica-tile-list {{::ctrl.cssClasses.harmonicaClassTileSubmenu}}"
        data-ng-class="{'harmonica-tile-submenu-active': ctrl.isVisibleTileSubmenu}"
        data-ng-if="ctrl.hoverTileSubmenu">
        <div
            class="harmonica-tile-row {{::ctrl.cssClasses.harmonicaClassTileRow}}"
            data-ng-repeat="link in ::ctrl.links | filter: {linkScope: {isVisibleInTile : true}} track by $index">
            <harmonica-link-in-tile data-link="link" data-class-classes="ctrl.cssClasses.harmonicaClassTileLink"> </harmonica-link-in-tile>
        </div>
    </div>
</div>
<!--</div>-->
