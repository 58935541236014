﻿(function (ng) {
    'use strict';

    var WindowCtrl = function () {
        var ctrl = this;
    };

    angular.module('windowExt').controller('WindowCtrl', WindowCtrl);

    WindowCtrl.$inject = [];
})(angular);
