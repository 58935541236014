import { PubSub } from '../../../_common/PubSub/PubSub.js';
(function (ng) {
  'use strict';

  var cartToolbar, timerPopoverHide;
  /*@ngInject*/
  /** @type{CartAddControllerFnType}
   *  @this{CartAddController}
   * */
  var CartAddCtrl = function ($document, $scope, $attrs, $parse, $q, $timeout, $window, cartConfig, cartService, moduleService, popoverService, SweetAlert, $translate, domService, toaster, customOptionsService) {
    var ctrl = this;
    ctrl.parseAttributes = function () {
      ctrl.data = {
        cartAddValid: $attrs.cartAddValid != null ? $parse($attrs.cartAddValid) : null,
        maxStepSpinbox: $attrs.maxStepSpinbox,
        minStepSpinbox: $attrs.minStepSpinbox,
        stepSpinbox: $attrs.stepSpinbox,
        href: $attrs.href,
        mode: $attrs.mode,
        lpId: $attrs.lpId,
        lpUpId: $attrs.lpUpId,
        lpEntityId: $attrs.lpEntityId,
        lpEntityType: $attrs.lpEntityType,
        lpBlockId: $attrs.lpBlockId,
        lpButtonName: $attrs.lpButtonName,
        hideShipping: $attrs.hideShipping,
        source: $attrs.source,
        modeFrom: $attrs.modeFrom,
        offerId: $attrs.offerId != null ? $parse($attrs.offerId)($scope) : null,
        productId: $attrs.productId != null ? $parse($attrs.productId)($scope) : null,
        amount: $attrs.amount != null ? $parse($attrs.amount)($scope) : null,
        attributesXml: $attrs.attributesXml != null ? $parse($attrs.attributesXml)($scope) : null,
        payment: $attrs.payment != null ? $parse($attrs.payment)($scope) : null,
        forceHiddenPopup: $parse($attrs.forceHiddenPopup)($scope),
        offerIds: $attrs.offerIds != null ? $parse($attrs.offerIds)($scope) : []
      };
      $scope.$watch($attrs.offerId, (newVal, oldVal) => {
        if (newVal === oldVal) return;
        ctrl.data.offerId = newVal;
      });
      $scope.$watch($attrs.productId, (newVal, oldVal) => {
        if (newVal === oldVal) return;
        ctrl.data.productId = newVal;
      });
      $scope.$watch($attrs.amount, (newVal, oldVal) => {
        if (newVal === oldVal) return;
        ctrl.data.amount = newVal;
      });
      $scope.$watch($attrs.attributesXml, (newVal, oldVal) => {
        if (newVal === oldVal) return;
        ctrl.data.attributesXml = newVal;
      });
      $scope.$watch($attrs.payment, (newVal, oldVal) => {
        if (newVal === oldVal) return;
        ctrl.data.payment = newVal;
      });
      $scope.$watchCollection($attrs.offerIds, (newVal, oldVal) => {
        if (newVal === oldVal) return;
        ctrl.data.offerIds = newVal;
      });
      $attrs.$observe('href', (newVal, oldVal) => {
        if (newVal === oldVal) return;
        ctrl.data.href = newVal;
      });
    };
    ctrl.isVisibleUpdateButton = function () {
      return ctrl.productCartData?.Amount > 0;
    };
    ctrl.$onInit = function () {
      ctrl.parseAttributes();
      if (ctrl.data.source != null && ctrl.data.source === 'mobile') {
        cartService.setStateInfo(true);
      }
      ctrl.needAdd = true;
      ctrl.isLoading = false;
      ctrl.refresh();
      PubSub.subscribe('cart.updateAmount', () => ctrl.refresh());
      PubSub.subscribe('cart.remove', offerId => {
        if (offerId === ctrl.data.offerId || ctrl.data.offerId === 0) {
          ctrl.refresh();
        }
      });
      PubSub.subscribe('cart.clear', () => ctrl.refresh());
      PubSub.subscribe('product.customOptions.change', ({
        productId,
        offerId,
        items
      }) => {
        if (offerId != null && ctrl.data.offerId !== offerId) return;
        if (productId !== ctrl.data.productId) return;
        const selectedOptions = customOptionsService.getSelectedOptions(items);
        ctrl.productCartData = cartService.findInCart(ctrl.data.productId, ctrl.data.offerId, selectedOptions);
      });
    };
    ctrl.addItem = event => {
      event.preventDefault();
      var isValid = ctrl.data.cartAddValid == null || ctrl.data.cartAddValid($scope);
      if (isValid === false) {
        return;
      }
      if (ctrl.isLoadingAdd == true) {
        return $q.resolve(null);
      }
      ctrl.isLoadingAdd = true;
      return cartService.addItem(ctrl.data).then(function (result) {
        const status = result[0].status;
        if (status === 'redirect') {
          if (result[0].url != null && result[0].url.length > 0) {
            $window.location.assign(result[0].url);
          } else {
            $window.location.assign(ctrl.data.href);
          }
        } else if (status === 'fail') {
          toaster.pop('error', '', $translate.instant('Js.CartAdd.Fail'));
        } else {
          PubSub.publish('add_to_cart', ctrl.data.href);
          PubSub.publish('cart.add', ctrl.data.offerId, ctrl.data.productId, ctrl.data.amount, ctrl.data.attributesXml, result['0'].cartId, event.target);
          PubSub.publish('cart.addv2', ctrl.data.productId, result['0'].cartId, result['0'].CartItem, event.target);
          moduleService.update(['minicartmessage', 'fullcartmessage']).then(ctrl.popoverModule);
        }
        ctrl.refresh(false);
        return result;
      }).then(function (result) {
        if (ctrl.data.source != null && ctrl.data.source === 'mobile' && result[0].status !== 'redirect') {
          cartService.showInfoWithDebounce();
        }
        return result;
      }).finally(result => {
        ctrl.isLoadingAdd = false;
        ctrl.isLoading = false;
        return result;
      });
    };
    ctrl.updateAmount = async function (value, itemId) {
      if (ctrl.isLoading || ctrl.isLoadingAdd) return $q.resolve(null);
      var item = {
        Key: itemId,
        Value: value
      };
      ctrl.isLoading = true;
      if (ctrl.productCartData.Amount == 0) {
        return cartService.removeItem(ctrl.productCartData.ShoppingCartItemId).then(() => {
          moduleService.update('fullcartmessage');
          ctrl.refresh();
        }).finally(() => {
          ctrl.needAdd = true;
          ctrl.isLoading = false;
        });
      } else {
        return cartService.updateAmount([item]).then(() => {
          moduleService.update('minicartmessage');
        }).finally(() => {
          ctrl.isLoading = false;
        });
      }
    };
    ctrl.refresh = function (cache) {
      return cartService.getData(cache).then(function (data) {
        ctrl.cartData = data;
        if (ctrl.cartData.CartProducts.length === 0) {
          ctrl.productCartData = null;
          ctrl.needAdd = true;
          return null;
        }
        const item = cartService.findInCart(ctrl.data.productId, ctrl.data.offerId === 0 ? null : ctrl.data.offerId);
        if (item) {
          ctrl.productCartData = item;
          ctrl.needAdd = false;
        } else {
          ctrl.productCartData = null;
          ctrl.needAdd = true;
        }
      });
    };
    ctrl.popoverModule = function (content) {
      if (moduleService.getModule('minicartmessage') != null && content[0].trim().length > 0) {
        $timeout(function () {
          popoverService.getPopoverScope('popoverCartToolbar').then(function (popoverScope) {
            cartToolbar = cartToolbar || document.getElementById('cartToolbar');
            popoverScope.active(cartToolbar);
            popoverScope.updatePosition(cartToolbar);
            if (timerPopoverHide != null) {
              $timeout.cancel(timerPopoverHide);
            }
            timerPopoverHide = $timeout(function () {
              popoverScope.deactive();
            }, 5000);
          });
        }, 0);
      }
    };
  };
  CartAddCtrl.$inject = ["$document", "$scope", "$attrs", "$parse", "$q", "$timeout", "$window", "cartConfig", "cartService", "moduleService", "popoverService", "SweetAlert", "$translate", "domService", "toaster", "customOptionsService"];
  angular.module('cart').controller('CartAddCtrl', CartAddCtrl);
})(window.angular);