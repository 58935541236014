﻿import './ApiShipOption.html';
import './BoxberryOption.html';
import './BoxberryWidgetOption.html';
import './CheckoutPointOption.html';
import './DDeliveryOption.html';
import './DDeliveryWidgetOption.html';
import './DpdOption.html';
import './EdostPickPointOption.html';
import './EdostSelectOption.html';
import './GrastinOption.html';
import './GrastinWidgetOption.html';
import './HermesOption.html';
import './HermesWidgetOption.html';
import './LPostPointOption.html';
import './MeasoftOption.html';
import './OzonRocketOption.html';
import './OzonRocketWidgetOption.html';
import './PecEasywayListOption.html';
import './PecOption.html';
import './PickPointOption.html';
import './PickPointWidjetOption.html';
import './PointDeliveryMapOption.html';
import './PointDeliverySelectOption.html';
import './RangePriceAndDistanceOption.html';
import './RangeWeightAndDistanceOption.html';
import './SberlogisticOption.html';
import './SberlogisticWidjetOption.html';
import './SdekOption.html';
import './SdekWidjetOption.html';
import './ShiptorWidgetOption.html';
import './YandexDeliveryOption.html';
import './YandexDeliveryPickupPointOption.html';
import './YandexDeliveryWidgetOption.html';
import './YandexNewDeliveryWidgetOption.html';
import './YandexNewPointDeliveryListOption.html';
import './DeliveryByZonesOption.html';
import './FivePostPointOption.html';
import './FivePostWidgetOption.html';
